import { createStore } from 'vuex';

const store = createStore({
  strict: process.env.NODE_ENV !== 'production',
  actions: {},
  mutations: {},
  modules: {},
});

export default store;
