import {createWebHistory, createRouter} from 'vue-router';

const RealTime = () => 
  import(/* webpackChunkName: "real-time" */ '../views/real-time/real-time.vue');
const LogFile = () => 
  import(/* webpackChunkName: "log-file" */ '../views/log-file/log-file.vue');
const Analysis = () => 
  import(/* webpackChunkName: "analysis" */ '../views/analysis/analysis.vue');
const Behaviour = () => 
  import(/* webpackChunkName: "behaviour" */ '../views/behaviour/behaviour.vue');
const Requests = () => 
  import(/* webpackChunkName: "requests" */ '../views/requests/requests.vue');
const RequestsYear = () => 
  import(/* webpackChunkName: "requests-year" */ '../views/requests-year/requests-year.vue');
const RequestsMonth = () => 
  import(/* webpackChunkName: "requests-month" */ '../views/requests-month/requests-month.vue');
const RequestsDay = () => 
  import(/* webpackChunkName: "requests-day" */ '../views/requests-day/requests-day.vue');
const RequestsHour = () => 
  import(/* webpackChunkName: "requests-hour" */ '../views/requests-hour/requests-hour.vue');

const routes = [
  { 
    path: '/',
    name: '',
    component: Analysis,
    children: [
      {
        path: '',
        name: 'requests',
        component: Requests,
        children: [
          {
            path: ':year',
            name: 'requestsYear',
            component: RequestsYear,
            children: [
              {
                path: ':month',
                name: 'requestsMonth',
                component: RequestsMonth,
                children: [
                  {
                    path: ':day',
                    name: 'requestsDay',
                    component: RequestsDay,
                    children: [
                      {
                        path: ':hour',
                        name: 'requestsHour',
                        component: RequestsHour
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'behaviour',
        name: 'behaviour',
        component: Behaviour 
      }
    ]
  },
  { 
    path: '/realtime',
    name: 'realTime',
    component: RealTime 
  },
  { 
    path: '/log-file',
    name: 'logFile',
    component: LogFile
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'nav__link--active',
  linkExactActiveClass: 'nav__link--exact-active',
});

export default router;
