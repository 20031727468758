<div class="main">
  <nav class="nav primary-nav">
    <ul class="nav__list primary-nav__list">
      <li class="nav__item nav__item--label">
        Beacon
      </li>
      <li v-for="item in menu"
        class="nav__item--link primary-nav__item">
        <router-link
          :exact="true" 
          class="nav__link primary-nav__link"
          :to="{name: item.name}">
          {{item.label}}
        </router-link>
      </li> 
    </ul>
  </nav>
  <router-view></router-view>
</div>
