export default {
  data() {
    return {
      menu: [
        {
          label: 'Analyse',
          name: 'requests',
        },
        {
          label: 'Real Time',
          name: 'realTime',
        },
        {
          label: 'Log Datei',
          name: 'logFile',
        },
      ],
    };
  },
};
